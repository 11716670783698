import React from 'react'

const LoginSignup = () => {
  return (
    <div>
     
    </div>
  )
}

export default LoginSignup
