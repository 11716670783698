import React from 'react'
import Cpurbo from '../Components/Cpturbo/CPurbo'
import Footer from '../Components/Footer/Footer'

const Cpturbo = () => {
  return (
    <div>
      <Cpurbo/>
      <Footer/>
    </div>
  )
}

export default Cpturbo
