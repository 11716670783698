import React from 'react'
import Cphoco from '../Components/Cpchoco/Cphoco'
import Footer from '../Components/Footer/Footer'
const Cpchoco = () => {
  return (
    <div>
      <Cphoco/>
<Footer/>
    </div>
  )
}

export default Cpchoco
