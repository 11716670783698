// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icons-main{
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-items:s;
    gap: 5px;
    margin-top: 700px;
   margin-bottom: 40px;
   
}

.icon-item{
    display:table-column;
    column-count: 8;
}
@media(max-width:708.021px){
    .icons-main{
       display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/IconsHome/Icons.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,QAAQ;IACR,iBAAiB;GAClB,mBAAmB;;AAEtB;;AAEA;IACI,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI;OACG,cAAc;IACjB;AACJ","sourcesContent":[".icons-main{\r\n    margin: 30px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-items:s;\r\n    gap: 5px;\r\n    margin-top: 700px;\r\n   margin-bottom: 40px;\r\n   \r\n}\r\n\r\n.icon-item{\r\n    display:table-column;\r\n    column-count: 8;\r\n}\r\n@media(max-width:708.021px){\r\n    .icons-main{\r\n       display: block;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
