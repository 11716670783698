// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 90px;
}
.vission-1{
  flex-basis: 50%
}
.vission-2{
  flex-basis: 40%;
}
.vission-img{
  width: 100%;
  border-radius: 10px;
}
.vission-2 b{
     font-size: 30px;
     color: rgb(36, 94, 94);
     font-family: Monospace;
     border-bottom: 1px solid red;
}
.vission-2 h3{
font-size: 35px;
color: #000f38;
margin: 10px  0;
font-family: serif;
max-width: 400px;
}
.vission-2 p{
  color: #676767;
  margin-bottom: 20px;
  line-height: 1.9;
  font-family: serif;
  font-size: 20px;
 text-align: justify;
}
/*media*/
@media(max-width:708.021px){
  .vission-2 b{
    font-size: 25px;
  }
  .vission-2 h3{
    font-size: 25px;
  }
  .row{
    flex-direction: column;
    margin-left: 15px;
  }
  .vission-1{
  flex-basis: 100%;
  margin: 20px;
  order: 2;
  }
  .vission-2{
flex-basis: 90%;
  }
  .vission-2 p{
    line-height: 1;
    margin-top: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Vission/Vission.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,UAAU;EACV,mBAAmB;AACrB;AACA;EACE;AACF;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;KACK,eAAe;KACf,sBAAsB;KACtB,sBAAsB;KACtB,4BAA4B;AACjC;AACA;AACA,eAAe;AACf,cAAc;AACd,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;CAChB,mBAAmB;AACpB;AACA,QAAQ;AACR;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,sBAAsB;IACtB,iBAAiB;EACnB;EACA;EACA,gBAAgB;EAChB,YAAY;EACZ,QAAQ;EACR;EACA;AACF,eAAe;EACb;EACA;IACE,cAAc;IACd,gBAAgB;EAClB;AACF","sourcesContent":[".row{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  width: 90%;\r\n  margin-bottom: 90px;\r\n}\r\n.vission-1{\r\n  flex-basis: 50%\r\n}\r\n.vission-2{\r\n  flex-basis: 40%;\r\n}\r\n.vission-img{\r\n  width: 100%;\r\n  border-radius: 10px;\r\n}\r\n.vission-2 b{\r\n     font-size: 30px;\r\n     color: rgb(36, 94, 94);\r\n     font-family: Monospace;\r\n     border-bottom: 1px solid red;\r\n}\r\n.vission-2 h3{\r\nfont-size: 35px;\r\ncolor: #000f38;\r\nmargin: 10px  0;\r\nfont-family: serif;\r\nmax-width: 400px;\r\n}\r\n.vission-2 p{\r\n  color: #676767;\r\n  margin-bottom: 20px;\r\n  line-height: 1.9;\r\n  font-family: serif;\r\n  font-size: 20px;\r\n text-align: justify;\r\n}\r\n/*media*/\r\n@media(max-width:708.021px){\r\n  .vission-2 b{\r\n    font-size: 25px;\r\n  }\r\n  .vission-2 h3{\r\n    font-size: 25px;\r\n  }\r\n  .row{\r\n    flex-direction: column;\r\n    margin-left: 15px;\r\n  }\r\n  .vission-1{\r\n  flex-basis: 100%;\r\n  margin: 20px;\r\n  order: 2;\r\n  }\r\n  .vission-2{\r\nflex-basis: 90%;\r\n  }\r\n  .vission-2 p{\r\n    line-height: 1;\r\n    margin-top: 25px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
