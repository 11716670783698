import React from 'react'
import Cpatrix from '../Components/Cpmatrix/Cpatrix'
import Footer from '../Components/Footer/Footer'
const Cpmatrix = () => {
  return (
    <div>
      <Cpatrix/>
      <Footer/>
    </div>
  )
}

export default Cpmatrix
