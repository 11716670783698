import React from 'react'
import Pptone from '../Components/Ppstone/Pptone'
import Footer from '../Components/Footer/Footer'
const Ppstone = () => {
  return (
    <div>
      <Pptone/>
      <Footer/>
    </div>
  )
}

export default Ppstone
