import React from 'react'
import Cpia from '../Components/CPkia/Cpia'
import Footer from '../Components/Footer/Footer'

const Cpkia = () => {
  return (
    <div>
      <Cpia/>
      <Footer/>
    </div>
  )
}

export default Cpkia
