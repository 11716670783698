import React from 'react'
import PPusion from '../Components/Ppfusion/PPusion'
import  Footer from '../Components/Footer/Footer'
const Ppfusion = () => {
  return (
    <div>
      <PPusion/>
<Footer/>
    </div>
  )
}

export default Ppfusion
