import kia1 from "./Kia1.png";
import kia2 from "./Kia2.png";
import kia3 from "./Kia3.png";
import kia4 from "./Kia4.png";
import kia5 from "./Kia5.png";
import kia6 from "./Kia6.png";
import kia7 from "./Kia7.png";
import kia8 from "./Kia8.png";
import kia9 from "./Kia9.png";
import kia10 from "./Kia10.png";
import kia11 from "./Kia11.png";
import kia12 from "./Kia12.png";

let data_product = [
  {
    id: 1,
    name: "Bib Cock",
    image: kia1,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 2,
    name: "Concealed Stop Cock",
    image: kia4,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 3,
    name: "2-1 angle Cock",
    image: kia7,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 4,
    name: "Sink Mixture",
    image: kia10,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 5,
    name: "Long body",
    image: kia2,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 6,
    name: "Pillar Cock",
    image: kia5,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 7,
    name: "Sink Cock",
    image: kia8,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 8,
    name: "Centre hole basin mixture",
    image: kia11,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 9,
    name: "Angle Cock",
    image: kia3,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 10,
    name: "2-1 Bib Cock",
    image: kia6,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 11,
    name: "Swan Neck",
    image: kia9,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 12,
    name: "Wall Mixture",
    image: kia12,
    new_price: 99,
    old_price: 199,
  },
];
export default data_product;
