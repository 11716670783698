import ppfusion1 from "./PPFusion1.png";
import ppfusion2 from "./PPFusion2.png";
import ppfusion3 from "./PPFusion3.png";
import ppfusion4 from "./PPFusion4.png";
import ppfusion5 from "./PPFusion5.png";
import ppfusion6 from "./PPFusion6.png";
import ppfusion7 from "./PPFusion7.png";
import ppfusion8 from "./PPFusion8.png";
import ppfusion9 from "./PPFusion9.png";

let data_product = [
  {
    id: 1,
    name: "Bib Cock",
    image: ppfusion1,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 2,
    name: "Pillar Cock",
    image: ppfusion4,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 3,
    name: "2-1 Bib Cock",
    image: ppfusion5,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 4,
    name: "Long body",
    image: ppfusion2,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 5,
    name: "Sink Cock",
    image: ppfusion7,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 6,
    name: "2-1 angle Cock",
    image: ppfusion6,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 7,
    name: "Bib Cock nozzle",
    image: ppfusion3,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 8,
    name: "Long body foam flow",
    image: ppfusion9,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 9,
    name: "Angle Cock",
    image: ppfusion8,
    new_price: 99,
    old_price: 199,
  },
];
export default data_product;
