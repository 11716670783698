// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.misson{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 90px;
}
.misson-1{
  flex-basis: 40%;
  margin: 20px;
}
.misson-2{
  flex-basis: 50%;
}
.misson-img{
  width: 110%;
  border-radius: 10px;
}
.misson-img:hover{
transform: scale(1.05);
transition: 0.4s;
}
.misson-1 b{
  align-items: center;
     font-size: 30px;
     color: rgb(36, 94, 94);
     font-family: Monospace;
     border-bottom: 1px solid red;
     text-align: center;
}
.misson-1 p{
  color: #676767;
  margin-bottom: 20px;
  line-height: 1.6;
  font-family: serif;
  font-size: 20px;
  margin-top: 50px;
  text-align: justify;
}
/*media*/
@media(max-width:708.021px){
  .misson{
    flex-direction: column;
  }
  .misson-1{
  flex-basis: 90%;
  margin: 20px;
  }
  .misson-2{
flex-basis: 100%;
  }
  .misson-img{
    width: 90%;
  }
  .misson-1 b{
    font-size: 25px;
  }
  .misson-1 p{
    line-height: 1;
    margin-top: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Misson/Misson.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,UAAU;EACV,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;AACA,sBAAsB;AACtB,gBAAgB;AAChB;AACA;EACE,mBAAmB;KAChB,eAAe;KACf,sBAAsB;KACtB,sBAAsB;KACtB,4BAA4B;KAC5B,kBAAkB;AACvB;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA,QAAQ;AACR;EACE;IACE,sBAAsB;EACxB;EACA;EACA,eAAe;EACf,YAAY;EACZ;EACA;AACF,gBAAgB;EACd;EACA;IACE,UAAU;EACZ;EACA;IACE,eAAe;EACjB;EACA;IACE,cAAc;IACd,gBAAgB;EAClB;AACF","sourcesContent":[".misson{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  width: 90%;\r\n  margin-bottom: 90px;\r\n}\r\n.misson-1{\r\n  flex-basis: 40%;\r\n  margin: 20px;\r\n}\r\n.misson-2{\r\n  flex-basis: 50%;\r\n}\r\n.misson-img{\r\n  width: 110%;\r\n  border-radius: 10px;\r\n}\r\n.misson-img:hover{\r\ntransform: scale(1.05);\r\ntransition: 0.4s;\r\n}\r\n.misson-1 b{\r\n  align-items: center;\r\n     font-size: 30px;\r\n     color: rgb(36, 94, 94);\r\n     font-family: Monospace;\r\n     border-bottom: 1px solid red;\r\n     text-align: center;\r\n}\r\n.misson-1 p{\r\n  color: #676767;\r\n  margin-bottom: 20px;\r\n  line-height: 1.6;\r\n  font-family: serif;\r\n  font-size: 20px;\r\n  margin-top: 50px;\r\n  text-align: justify;\r\n}\r\n/*media*/\r\n@media(max-width:708.021px){\r\n  .misson{\r\n    flex-direction: column;\r\n  }\r\n  .misson-1{\r\n  flex-basis: 90%;\r\n  margin: 20px;\r\n  }\r\n  .misson-2{\r\nflex-basis: 100%;\r\n  }\r\n  .misson-img{\r\n    width: 90%;\r\n  }\r\n  .misson-1 b{\r\n    font-size: 25px;\r\n  }\r\n  .misson-1 p{\r\n    line-height: 1;\r\n    margin-top: 25px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
