import React from 'react'
import Suport from '../Components/Suport/Suport'
const Support = () => {
  return (
    <div>
      <Suport/>
    </div>
  )
}

export default Support
