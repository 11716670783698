import React from 'react'
import Enquiry from '../Components/Enquiry/Enquiry'
import Footer from '../Components/Footer/Footer'


const ShopCategory = () => {
  return (
    <div>
      <Enquiry/>
      
      <Footer/>
      
    </div>
  )
}

export default ShopCategory
