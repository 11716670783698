import pproyle1 from "./PPRoyal1.png";
import pproyle2 from "./PPRoyal2.png";
import pproyle3 from "./PPRoyal3.png";
import pproyle4 from "./PPRoyal7.png";
import pproyle5 from "./PPRoyal4.png";
import pproyle6 from "./PPRoyal5.png";
import pillar from "./pillar.png";
import swang from "./swang.png";

let data_product = [
  {
    id: 1,
    name: "Bib Cock",
    image: pproyle1,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 2,
    name: "2-1 Bib Cock",
    image: pproyle5,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 3,
    name: "Swan Neck",
    image: swang,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 4,
    name: "Long body",
    image: pproyle2,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 5,
    name: "2-1 angle Cock",
    image: pproyle6,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 6,
    name: "Sing cock",
    image: pproyle4,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 7,
    name: "Angle Cock",
    image: pproyle3,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 8,
    name: "Pillar Cock",
    image: pillar,
    new_price: 99,
    old_price: 199,
  },
];
export default data_product;
