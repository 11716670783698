import React from 'react'
import Ptmttone from '../Components/Ptmtstone/Ptmttone'
import Footer from '../Components/Footer/Footer'
const Ptmtstone = () => {
  return (
    <div>
      <Ptmttone/>
      <Footer/>
    </div>
  )
}

export default Ptmtstone
