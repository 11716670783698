// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing{
  margin-top: 10%;
  margin-bottom: 30%;
}
.landing-1{
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: rgb(235, 236, 228);
 
}
.landing-1 b{
  margin-top: 20px;
  font-size: 25px;
  font-family: Monospace;
  margin-bottom: 20px;
  color: rgb(36, 94, 94);
}
.landing-1 p{
  max-width: 95%;
  text-align: center;
  font-size: 20px;
  margin: 10px auto 20px;
  line-height: 1.4; 
  font-family: oblique;
}
.landing-1 h3{
  font-size: 20px;
    margin: 20px;
    line-height: 1.4;
    font-family: oblique;
    border-bottom: 1px solid rgba(0, 0, 0, 0.308);
}
.imgl{
  display: flex;
align-items: center;
justify-content: space-between;
border-bottom: 1px solid rgb(18, 59, 75);

}
.imgl img{
  width: 40px;
  height: 40px;
}
/*---media ----*/
@media(max-width:708.021px){
  .landing{
    margin-top:430%;
    margin-bottom: 150%;
  }
  .landing-1 p{
    text-align: justify;
    line-height: normal;
    margin: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Landing/Landing.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,oCAAoC;;AAEtC;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,eAAe;IACb,YAAY;IACZ,gBAAgB;IAChB,oBAAoB;IACpB,6CAA6C;AACjD;AACA;EACE,aAAa;AACf,mBAAmB;AACnB,8BAA8B;AAC9B,wCAAwC;;AAExC;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA,gBAAgB;AAChB;EACE;IACE,eAAe;IACf,mBAAmB;EACrB;EACA;IACE,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;EACd;AACF","sourcesContent":[".landing{\r\n  margin-top: 10%;\r\n  margin-bottom: 30%;\r\n}\r\n.landing-1{\r\n  position: absolute;\r\n  align-items: center;\r\n  justify-content: center;\r\n  display: flex;\r\n  flex-direction: column;\r\n  background-color: rgb(235, 236, 228);\r\n \r\n}\r\n.landing-1 b{\r\n  margin-top: 20px;\r\n  font-size: 25px;\r\n  font-family: Monospace;\r\n  margin-bottom: 20px;\r\n  color: rgb(36, 94, 94);\r\n}\r\n.landing-1 p{\r\n  max-width: 95%;\r\n  text-align: center;\r\n  font-size: 20px;\r\n  margin: 10px auto 20px;\r\n  line-height: 1.4; \r\n  font-family: oblique;\r\n}\r\n.landing-1 h3{\r\n  font-size: 20px;\r\n    margin: 20px;\r\n    line-height: 1.4;\r\n    font-family: oblique;\r\n    border-bottom: 1px solid rgba(0, 0, 0, 0.308);\r\n}\r\n.imgl{\r\n  display: flex;\r\nalign-items: center;\r\njustify-content: space-between;\r\nborder-bottom: 1px solid rgb(18, 59, 75);\r\n\r\n}\r\n.imgl img{\r\n  width: 40px;\r\n  height: 40px;\r\n}\r\n/*---media ----*/\r\n@media(max-width:708.021px){\r\n  .landing{\r\n    margin-top:430%;\r\n    margin-bottom: 150%;\r\n  }\r\n  .landing-1 p{\r\n    text-align: justify;\r\n    line-height: normal;\r\n    margin: 30px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
