import React from 'react'
import Sinkixar from '../Components/Sinkmixar/Sinkixar'
import Footer from '../Components/Footer/Footer'

const Sinkmixar = () => {
  return (
    <div>
      <Sinkixar/>
      <Footer/>
    </div>
  )
}

export default Sinkmixar
