import React from 'react'
import Hero from '../Components/Hero/Hero'


const SCategories = () => {
  return (
    <div>
       <Hero/>
      
    </div>
  )
}

export default SCategories
