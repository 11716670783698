// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.vailu{
    width: 97%;
    margin: 10px;
    width:60%;
    margin-bottom: 40px;
    
}
.vailu b{
    margin-left: 380px;
    margin-top: 20px;
    border-bottom: 1px solid red;
    font-size: 38px;
   color: rgb(36, 94, 94);
   font-family: Monospace;
}
.vailu h3{
    margin-top: 20px;
    text-align: center;
    line-height: 1.4;
    font-size: 20px;
    font-family: oblique;
    
}
.values{
    margin-top: 30px;
    background-color: rgb(39, 37, 34);
    color: aliceblue;
    border-radius: 3px;
    
}
.values p{
    margin: 40px;
    text-align: center;
    line-height: 1.8;
    font-family: serif;
    font-size: 20px;
    
}
.valuess p{
    text-align: center;
    line-height: 1.8;
    font-family: serif;
    font-size: 20px;
    margin-top: 30px;
    color: #676767;
}
/*media*/
@media(max-width:708.021px){
    .vailu{
      display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Value/Value.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,YAAY;IACZ,SAAS;IACT,mBAAmB;;AAEvB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;IAC5B,eAAe;GAChB,sBAAsB;GACtB,sBAAsB;AACzB;AACA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,oBAAoB;;AAExB;AACA;IACI,gBAAgB;IAChB,iCAAiC;IACjC,gBAAgB;IAChB,kBAAkB;;AAEtB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;;AAEnB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA,QAAQ;AACR;IACI;MACE,aAAa;IACf;AACJ","sourcesContent":["\r\n.vailu{\r\n    width: 97%;\r\n    margin: 10px;\r\n    width:60%;\r\n    margin-bottom: 40px;\r\n    \r\n}\r\n.vailu b{\r\n    margin-left: 380px;\r\n    margin-top: 20px;\r\n    border-bottom: 1px solid red;\r\n    font-size: 38px;\r\n   color: rgb(36, 94, 94);\r\n   font-family: Monospace;\r\n}\r\n.vailu h3{\r\n    margin-top: 20px;\r\n    text-align: center;\r\n    line-height: 1.4;\r\n    font-size: 20px;\r\n    font-family: oblique;\r\n    \r\n}\r\n.values{\r\n    margin-top: 30px;\r\n    background-color: rgb(39, 37, 34);\r\n    color: aliceblue;\r\n    border-radius: 3px;\r\n    \r\n}\r\n.values p{\r\n    margin: 40px;\r\n    text-align: center;\r\n    line-height: 1.8;\r\n    font-family: serif;\r\n    font-size: 20px;\r\n    \r\n}\r\n.valuess p{\r\n    text-align: center;\r\n    line-height: 1.8;\r\n    font-family: serif;\r\n    font-size: 20px;\r\n    margin-top: 30px;\r\n    color: #676767;\r\n}\r\n/*media*/\r\n@media(max-width:708.021px){\r\n    .vailu{\r\n      display: none;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
