// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thought-main{
    width: 95%;
    margin: 30px;
    margin-top: 30px;
    height:50vh;
}
.thought-main h4{
    font-size: 30px;
    font-family: Monospace;
    color: rgba(194, 165, 39, 0.829);
    text-align: center;
}
.thought{
    margin-top: 50px;
}
.thought h3{
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.4;
    font-size: 20px;
    font-family: oblique;
   color: rgb(36, 94, 94);
}
.thought p{
    text-align: justify;
    line-height: 1.8;
    font-family: serif;
    font-size: 20px;
    margin: 10px;
}
/*media*/
@media(max-width:708.021px){
    
    .thought p{
        text-align: justify;
        line-height: 1.8;
        font-family: serif;
        font-size: 20px;
        width: 90%;
    }
    .thought-main h4{
        font-size: 25px;
        font-family: serif;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Thought/Thought.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,eAAe;IACf,sBAAsB;IACtB,gCAAgC;IAChC,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,oBAAoB;GACrB,sBAAsB;AACzB;AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;AACA,QAAQ;AACR;;IAEI;QACI,mBAAmB;QACnB,gBAAgB;QAChB,kBAAkB;QAClB,eAAe;QACf,UAAU;IACd;IACA;QACI,eAAe;QACf,kBAAkB;IACtB;AACJ","sourcesContent":[".thought-main{\r\n    width: 95%;\r\n    margin: 30px;\r\n    margin-top: 30px;\r\n    height:50vh;\r\n}\r\n.thought-main h4{\r\n    font-size: 30px;\r\n    font-family: Monospace;\r\n    color: rgba(194, 165, 39, 0.829);\r\n    text-align: center;\r\n}\r\n.thought{\r\n    margin-top: 50px;\r\n}\r\n.thought h3{\r\n    margin-bottom: 20px;\r\n    text-align: center;\r\n    line-height: 1.4;\r\n    font-size: 20px;\r\n    font-family: oblique;\r\n   color: rgb(36, 94, 94);\r\n}\r\n.thought p{\r\n    text-align: justify;\r\n    line-height: 1.8;\r\n    font-family: serif;\r\n    font-size: 20px;\r\n    margin: 10px;\r\n}\r\n/*media*/\r\n@media(max-width:708.021px){\r\n    \r\n    .thought p{\r\n        text-align: justify;\r\n        line-height: 1.8;\r\n        font-family: serif;\r\n        font-size: 20px;\r\n        width: 90%;\r\n    }\r\n    .thought-main h4{\r\n        font-size: 25px;\r\n        font-family: serif;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
