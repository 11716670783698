import fusion1 from "./Fusion1.png";
import fusion2 from "./Fusion2.png";
import fusion3 from "./Fusion3.png";
import fusion4 from "./Fusion4.png";
import fusion5 from "./Fusion5.png";
import fusion6 from "./Fusion6.png";
import fusion7 from "./Fusion7.png";
import fusion8 from "./Fusion8.png";
import fusion9 from "./Fusion9.png";
import fusion10 from "./Fusion10.png";
import fusion11 from "./Fusion11.png";
import fusion12 from "./Fusion12.png";

let data_product = [
  {
    id: 1,
    name: "Bib Cock",
    image: fusion1,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 2,
    name: "Concealed Stop Cock",
    image: fusion4,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 3,
    name: "2-1 angle Cock",
    image: fusion7,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 4,
    name: "Sink Mixture",
    image: fusion10,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 5,
    name: "Long body",
    image: fusion2,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 6,
    name: "Pillar Cock",
    image: fusion5,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 7,
    name: "Sink Cock",
    image: fusion8,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 8,
    name: "Centre hole basin mixture",
    image: fusion11,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 9,
    name: "Angle Cock",
    image: fusion3,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 10,
    name: "2-1 Bib Cock",
    image: fusion6,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 11,
    name: "Swan Neck",
    image: fusion9,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 12,
    name: "Wall Mixture",
    image: fusion12,
    new_price: 99,
    old_price: 199,
  },
];
export default data_product;
