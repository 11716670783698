import React from 'react'
import Divartorupparb from '../Components/Divartorupper/Divartorupparb'
import Footer from '../Components/Footer/Footer'
const Divartorupper = () => {
  return (
    <div>
      <Divartorupparb/>
      <Footer/>
    </div>
  )
}

export default Divartorupper
