import React from 'react'
 import Alive from '../Components/Ptmtalivemain/Alive'
 import Footer from '../Components/Footer/Footer'

const Ptmtalive = () => {
  return (
    <div>
    <Alive/>
    <Footer/>
    </div>
  )
}

export default Ptmtalive
