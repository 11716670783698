import React from 'react'
import Ppoyal from '../Components/Pproyal/Ppoyal'
import Footer from '../Components/Footer/Footer'
const Pproyal = () => {
  return (
    <div>
      <Ppoyal/>
     <Footer/>
    </div>
  )
}

export default Pproyal
