// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  
}
body {
  background: #f5f5f5;
  width: 100%;
  height: 100vh;
  
}
a{
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
  font-family: "times New Roman", Times, serif;
}
a:hover{
  color: rgba(209, 168, 81, 0.795);
    transition: .3s;
}
.container{
  padding-left: 10%;
  padding-right: 10%;
  
}
/*-----media query------*/
@media (max-width:1200px){
  .container{
    padding-left: 5%;
    padding-right: 5%;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,iCAAiC;;AAEnC;AACA;EACE,mBAAmB;EACnB,WAAW;EACX,aAAa;;AAEf;AACA;EACE,qBAAqB;EACrB,cAAc;EACd,cAAc;EACd,eAAe;EACf,4CAA4C;AAC9C;AACA;EACE,gCAAgC;IAC9B,eAAe;AACnB;AACA;EACE,iBAAiB;EACjB,kBAAkB;;AAEpB;AACA,yBAAyB;AACzB;EACE;IACE,gBAAgB;IAChB,iBAAiB;EACnB;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');\n*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Roboto\", sans-serif;\n  \n}\nbody {\n  background: #f5f5f5;\n  width: 100%;\n  height: 100vh;\n  \n}\na{\n  text-decoration: none;\n  color: inherit;\n  line-height: 1;\n  cursor: pointer;\n  font-family: \"times New Roman\", Times, serif;\n}\na:hover{\n  color: rgba(209, 168, 81, 0.795);\n    transition: .3s;\n}\n.container{\n  padding-left: 10%;\n  padding-right: 10%;\n  \n}\n/*-----media query------*/\n@media (max-width:1200px){\n  .container{\n    padding-left: 5%;\n    padding-right: 5%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
