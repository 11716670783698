import React from 'react'
import About from '../Components/About/About'
import Landing from '../Components/Landing/Landing'
import Footer from '../Components/Footer/Footer'



const home = () => {
  return (
    <div>
      
      <About/>
      
      <Landing/>
     <Footer/>
    </div>
  )
}

export default home
