import bibcock from "./BibCock.png";
import longbody from "./Longbody.png";
import anglecock from "./AngleCock.png";
import anglecock1 from "./angleCockwhite.png";
import blackcock from "./BibCockan.png";
import sinkcock from "./SinkCock.png";
import SwanNeck from "./SwanNeck.png";

let data_product = [
  {
    id: 1,
    name: "Bib cock",
    image: bibcock,
  },
  {
    id: 2,
    name: "2-1 Bib Cock",
    image: blackcock,
  },
  {
    id: 3,
    name: "Long Body",
    image: longbody,
  },
  {
    id: 4,
    name: "Sink Cock",
    image: sinkcock,
  },
  {
    id: 5,
    name: "Angle Cock",
    image: anglecock,
  },
  {
    id: 6,
    name: "Swan Neck",
    image: SwanNeck,
  },
  {
    id: 7,
    name: "2-1 angle Cock",
    image: anglecock1,
  },
];
export default data_product;
