import PTMTStone1 from "./PTMTStone1.png";
import PTMTStone2 from "./PTMTStone2.png";
import PTMTStone3 from "./PTMTStone3.png";
import PTMTStone4 from "./PTMTStone4.png";
import PTMTStone5 from "./PTMTStone5.png";
import PTMTStone6 from "./PTMTStone6.png";
import PTMTStone7 from "./PTMTStone7.png";
import PTMTStone8 from "./PTMTStone8.png";
//import PTMTStone9 from "./ptm";
//import PTMTStone10 from "./PTMTStone10.png";
//import PTMTStone11 from "./PTMTStone11.png";
//import PTMTStone12 from "./PTMTStone12.png";

let data_product = [
  {
    id: 1,
    name: "Bib Coco",
    image: PTMTStone1,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 2,
    name: "2-1 Angle Cocok",
    image: PTMTStone4,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 3,
    name: "Swan Neck",
    image: PTMTStone7,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 4,
    name: "Long Body",
    image: PTMTStone2,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 5,
    name: "Sink Cock",
    image: PTMTStone5,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 6,
    name: "Wall Mixer ",
    image: PTMTStone8,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 7,
    name: "Angle Cock",
    image: PTMTStone3,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 8,
    name: "Sink Cock",
    image: PTMTStone6,
    new_price: 99,
    old_price: 199,
  },
  {
    /*{
    id: 9,
    name: "PTMTStone9",
    image: PTMTStone9,
    new_price: 99,
    old_price: 199,
  },*/
  },
];
export default data_product;
