import React from 'react'
import Showe from '../Components/Shower/Showe'
import Footer from '../Components/Footer/Footer'
const Shower = () => {
  return (
    <div>
      <Showe/>
      <Footer/>
    </div>
  )
}

export default Shower
