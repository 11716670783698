// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aliveitem{
    cursor: pointer;
    width: 73%;
    height: 52vh;
    margin-bottom: 30px;
    
    
}
.aliveitem:hover{
    transform: scale(1.05);
    transition: 0.6s;
    background-color: rgba(124, 116, 107, 0.616);
}

.aliveitem p{
    color:black;
    margin-left: 60px;
    font-size: 18px;
    font-family: oblique;
}
`, "",{"version":3,"sources":["webpack://./src/Components/PtmtAlive/Aliveitem.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,YAAY;IACZ,mBAAmB;;;AAGvB;AACA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,4CAA4C;AAChD;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".aliveitem{\r\n    cursor: pointer;\r\n    width: 73%;\r\n    height: 52vh;\r\n    margin-bottom: 30px;\r\n    \r\n    \r\n}\r\n.aliveitem:hover{\r\n    transform: scale(1.05);\r\n    transition: 0.6s;\r\n    background-color: rgba(124, 116, 107, 0.616);\r\n}\r\n\r\n.aliveitem p{\r\n    color:black;\r\n    margin-left: 60px;\r\n    font-size: 18px;\r\n    font-family: oblique;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
