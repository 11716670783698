// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itemic{
    cursor: pointer;
}
.itemic:hover{
    transform: scale(1.05);
    transition: 0.6s;
}
.icon-img{
    height: 70px;
    width: 80px;
    margin-top: 40px;
    
}
.itemic p{
    color: rgb(182, 113, 23);
    margin: 6px 0px;
margin-bottom: 40px;
margin-left: 10px;
font-family: copperplate, Papyrus, fantasy;
}`, "",{"version":3,"sources":["webpack://./src/Components/IconsItem/IconsItem.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,WAAW;IACX,gBAAgB;;AAEpB;AACA;IACI,wBAAwB;IACxB,eAAe;AACnB,mBAAmB;AACnB,iBAAiB;AACjB,0CAA0C;AAC1C","sourcesContent":[".itemic{\r\n    cursor: pointer;\r\n}\r\n.itemic:hover{\r\n    transform: scale(1.05);\r\n    transition: 0.6s;\r\n}\r\n.icon-img{\r\n    height: 70px;\r\n    width: 80px;\r\n    margin-top: 40px;\r\n    \r\n}\r\n.itemic p{\r\n    color: rgb(182, 113, 23);\r\n    margin: 6px 0px;\r\nmargin-bottom: 40px;\r\nmargin-left: 10px;\r\nfont-family: copperplate, Papyrus, fantasy;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
