// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arivels-main p{
margin-top:100px;
    text-align: center;
    font-size: 50px;
    margin-bottom: 40px;
    font-family: 'Courier New', monospace;
    color: rgba(194, 165, 39, 0.829);
   
}
.arivels{
margin: 20px auto;
display: flex;
align-items: center;
justify-content: space-between;

}
.arivel img{
    width: 100%;
    height: 50vh;
    display: block;
    margin-bottom: 50px;
}
.arivel{
    flex-basis: 23%;
position: relative;
margin: 8px;
}
.arivel:hover img{
    transform: scale(1.05);
    transition:all 0.4s;
}

/*arivel*/
@media(max-width:708.021px){
    .arivels{
        flex-direction: column;
    }
    .arivels-main p{
        margin-top:100px;
            text-align: center;
            font-size: 30px;
            margin-bottom: 40px;
            font-family: 'Courier New', monospace;
            color: rgba(194, 165, 39, 0.829);
           
        }
}`, "",{"version":3,"sources":["webpack://./src/Components/Arivels/Arivels.css"],"names":[],"mappings":"AAAA;AACA,gBAAgB;IACZ,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,qCAAqC;IACrC,gCAAgC;;AAEpC;AACA;AACA,iBAAiB;AACjB,aAAa;AACb,mBAAmB;AACnB,8BAA8B;;AAE9B;AACA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB,kBAAkB;AAClB,WAAW;AACX;AACA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA,SAAS;AACT;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,gBAAgB;YACZ,kBAAkB;YAClB,eAAe;YACf,mBAAmB;YACnB,qCAAqC;YACrC,gCAAgC;;QAEpC;AACR","sourcesContent":[".arivels-main p{\r\nmargin-top:100px;\r\n    text-align: center;\r\n    font-size: 50px;\r\n    margin-bottom: 40px;\r\n    font-family: 'Courier New', monospace;\r\n    color: rgba(194, 165, 39, 0.829);\r\n   \r\n}\r\n.arivels{\r\nmargin: 20px auto;\r\ndisplay: flex;\r\nalign-items: center;\r\njustify-content: space-between;\r\n\r\n}\r\n.arivel img{\r\n    width: 100%;\r\n    height: 50vh;\r\n    display: block;\r\n    margin-bottom: 50px;\r\n}\r\n.arivel{\r\n    flex-basis: 23%;\r\nposition: relative;\r\nmargin: 8px;\r\n}\r\n.arivel:hover img{\r\n    transform: scale(1.05);\r\n    transition:all 0.4s;\r\n}\r\n\r\n/*arivel*/\r\n@media(max-width:708.021px){\r\n    .arivels{\r\n        flex-direction: column;\r\n    }\r\n    .arivels-main p{\r\n        margin-top:100px;\r\n            text-align: center;\r\n            font-size: 30px;\r\n            margin-bottom: 40px;\r\n            font-family: 'Courier New', monospace;\r\n            color: rgba(194, 165, 39, 0.829);\r\n           \r\n        }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
