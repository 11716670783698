import React from 'react'
import Ptmtusion from '../Components/Ptmtfusion/Ptmtusion'
import Footer from '../Components/Footer/Footer'
const Ptmtfusion = () => {
  return (
    <div>
      <Ptmtusion/>
     <Footer/>
    </div>
  )
}

export default Ptmtfusion
