import icons from "./bathtub.png";
import icons1 from "./faucet.png";
import icons2 from "./shower.png";
import icons3 from "./shower2.png";
import icons4 from "./hand.png";
import icons5 from "./faucet2.png";
import icons6 from "./plumbing.png";
import icons7 from "./water-tap.png";

let data_product = [
  {
    id: 1,
    name: "Rain fall Shower",
    image: icons,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 2,
    name: "Swan Neck",
    image: icons1,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 3,
    name: "Shower head",
    image: icons2,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 4,
    name: "Hand Shower",
    image: icons3,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 5,
    name: "Health faucet",
    image: icons4,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 6,
    name: "Bib Cock",
    image: icons5,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 7,
    name: "divertor bdy",
    image: icons6,
    new_price: 99,
    old_price: 199,
  },
  {
    id: 8,
    name: "pillar cock",
    image: icons7,
    new_price: 99,
    old_price: 199,
  },
];
export default data_product;
